import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import { useForm } from "../../shared/hooks/form-hook";
import { AuthContext } from "../../shared/context/auth-context";
import Input from "../../shared/components/FormElements/Input";
import Card from "../../shared/components/UIElements/Card";
import Button from "../../shared/components/FormElements/Button";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
} from "../../shared/util/validators";


import "./Auth.css";

const Auth = (props) => {
  const auth = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  let history = useHistory();

  const [formState, inputHandler] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const authSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/anmelden", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //Authorization: 'Bearer ' + auth.token,
        },
        body: JSON.stringify({
          email: formState.inputs.email.value,
          password: formState.inputs.password.value,
        }),
      });

      const responseData = await response.json();
      // filter for 4** and 5** error codes
      if (!response.ok) {
        throw new Error(responseData.message);
      }
      setIsLoading(false);
      auth.login(responseData.userId, responseData.token);
      history.push("/bwt-admin");
    } catch (err) {
      setIsLoading(false);
      setError(
        err.message || "Ein Fehler ist aufgetreten, bitte versuche es nochmals"
      );
    }
  };

  const backHandler = () => {
    history.push("/bwt-admin");
  };

  const errorHandler = () => {
    setError(null);
  }

  return (
    <>
    <ErrorModal error={error} onClear={errorHandler} />
    <Card className="authentication">
      {isLoading && <LoadingSpinner asOverlay />}
      <h1>Neuen Benutzer erstellen</h1>

      <form onSubmit={authSubmitHandler} className="form-control">
        <Input
          id="email"
          element="input"
          type="email"
          placeholder="e-mail"
          errorText="Bitte erfasse eine gültige E-Mail."
          validators={[VALIDATOR_EMAIL()]}
          onInput={inputHandler}
        />
        <Input
          id="password"
          element="input"
          type="password"
          placeholder="passwort"
          errorText="Bitte erfasse ein gültiges Passwort mit mindestens 8 Zeichen."
          validators={[VALIDATOR_MINLENGTH(8)]}
          onInput={inputHandler}
        />
        <Button type="button" onClick={backHandler} className="button--inverse">
          ZURÜCK
        </Button>
        <Button type="submit" disabled={!formState.isValid}>
          ERSTELLEN
        </Button>
      </form>
    </Card>
    </>
  );
};

export default Auth;
