import React from "react";

import './Header.css'

const Header = () => {
    return (
        <div className="logo--header">
        <header className="header bg-gray-lightest pb-md-4">

        <div className="header__top bg-white"></div>
        <div className="container-fluid header__branding pos-r">
            <a href="https://www.projuventute.ch/">
                <img src="https://www.projuventute.ch/themes/custom/projuventute/logo.svg" alt="logo" />
            </a>
        </div>
    </header>
    </div>
    )
}

export default Header;