import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../shared/components/FormElements/Button";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import Wrapper from "../../shared/components/UIElements/Wrapper";
import "../pages/Volunteer.css";

const Angaben = (props) => {
  const [contentDe, contentFr, contentIt] = props.content;
  let history = useHistory();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [schonteilgenommen, setSchonteilgenommen] = useState(false);

  const schonteilgenommenHandler = (event) => {
    schonteilgenommen
      ? setSchonteilgenommen(false)
      : setSchonteilgenommen(true);
  };

  // const strg = "";
  // console.log("quotes entfernt final: ", strg.replace(/["\']+/g, ""));

  const formSubmitHandler = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    console.log(formData.get("file"));
    console.log("form data: ", formData.get("dateids"));

    // remove quotation marks:
    const b = formData.get("bemerkungen");
    const m = formData.get("motivation");
    console.log("b und m sind: ", b);
    if (b) {
      formData.set("bemerkungen", b.toString().replace(/["\']+/g, ""));
    }
    if (m) {
      formData.set("motivation", m.toString().replace(/["\']+/g, ""));
    }

    try {
      setIsLoading(true);
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + `/${props.lang}/volunteer`,
        //`https://prod-24.switzerlandnorth.logic.azure.com:443/workflows/9db9522ec13a44e6ba773ad24fe04472/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Feoq9-KWj0LKoYxIVRvGVI1mrFNf9JWKMmxBLVOgkvE`,
        {
          method: "POST",
          body: formData,
        }
      );

      const responseData = await response.json();
      // filter for 4** and 5** error codes
      if (!response.ok) {
        throw new Error(responseData.message);
        //throw new Error("response", response)
      }
      console.log("responseData: ", responseData);
      setIsLoading(false);
      history.push(`/${props.lang}/danke`);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      if (props.lang === "fr") {
        setError(err.message || contentFr.system.fehler1);
      } else if (props.lang === "it") {
        setError(err.message || contentIt.system.fehler1);
      } else {
        setError(err.message || contentDe.system.fehler1);
        console.log("err.message: ", err.message);
      }
    }
  };

  const backBtnHandler = () => {
    history.push(`/${props.lang}/daten`);
  };
  const errorHandler = () => {
    setError(null);
  };

  return (
    <>
      <ErrorModal error={error} onClear={errorHandler} />
      <Wrapper>
        {isLoading && <LoadingSpinner asOverlay />}
        <form
          id="bwt-form"
          onSubmit={formSubmitHandler}
          method="post"
          // action={`${process.env.REACT_APP_BACKEND_URL}/${props.lang}/volunteer`}
          // encType="multipart/form-data"
          className="form-control"
        >
          <>
            <h3>
              {props.lang === "it"
                ? contentIt.titleOrigin
                : props.lang === "fr"
                ? contentFr.titleOrigin
                : contentDe.titleOrigin}
            </h3>
            <input type="hidden" name="dateids" value={props.dateids} />
            <input
              type="hidden"
              name="wunschkanton"
              value={props.wunschkanton}
            />
            <div className="fr_displayInlineBlock fr_marginTopS">
              <input
                id="privatperson"
                type="radio"
                name="origin"
                value="privatperson"
                className="fr_marginLeftS"
                required
              />
              <label htmlFor="privatperson" className="fr_marginRightM">
                {props.lang === "it"
                  ? contentIt.privatperson
                  : props.lang === "fr"
                  ? contentFr.privatperson
                  : contentDe.privatperson}
              </label>
            </div>

            <div className="fr_displayInlineBlock fr_marginTopS">
              <input
                id="arbeitgeber"
                type="radio"
                name="origin"
                value="arbeitgeber"
                className="fr_marginLeftS"
                required
              />
              <label htmlFor="arbeitgeber">
                {props.lang === "it"
                  ? contentIt.arbeitgeber
                  : props.lang === "fr"
                  ? contentFr.arbeitgeber
                  : contentDe.arbeitgeber}
              </label>
            </div>
            <div className="fr_clear fr_marginBottomXl"></div>

            <h3>
              {props.lang === "it"
                ? contentIt.anrede
                : props.lang === "fr"
                ? contentFr.anrede
                : contentDe.anrede}
            </h3>
            <input
              id="herr"
              type="radio"
              name="anrede"
              value="herr"
              className="fr_marginLeftS"
              required
            />
            <label htmlFor="herr" className="fr_marginRightM">
              {props.lang === "it"
                ? contentIt.herr
                : props.lang === "fr"
                ? contentFr.herr
                : contentDe.herr}
            </label>

            <input
              id="frau"
              type="radio"
              name="anrede"
              value="frau"
              className="fr_marginLeftS"
              required
            />
            <label htmlFor="frau" className="fr_marginRightM">
              {props.lang === "it"
                ? contentIt.frau
                : props.lang === "fr"
                ? contentFr.frau
                : contentDe.frau}
            </label>

            <input
              id="divers"
              type="radio"
              name="anrede"
              value="divers"
              className="fr_marginLeftS"
              required
            />
            <label htmlFor="divers">
              {props.lang === "it"
                ? contentIt.divers
                : props.lang === "fr"
                ? contentFr.divers
                : contentDe.divers}
            </label>
            <div className="fr_clear fr_marginBottomXl"></div>

            <input
              id="vorname"
              type="text"
              name="vorname"
              placeholder={
                props.lang === "it"
                  ? contentIt.vorname
                  : props.lang === "fr"
                  ? contentFr.vorname
                  : contentDe.vorname
              }
              required
            />

            <input
              id="name"
              type="text"
              name="name"
              placeholder={
                props.lang === "it"
                  ? contentIt.name
                  : props.lang === "fr"
                  ? contentFr.name
                  : contentDe.name
              }
              className="fr_marginTopS"
              required
            />

            <input
              id="mobile"
              type="tel"
              name="mobile"
              placeholder={
                props.lang === "it"
                  ? contentIt.mobile
                  : props.lang === "fr"
                  ? contentFr.mobile
                  : contentDe.mobile
              }
              className="fr_marginTopS"
              required
            />

            <input
              id="email"
              type="email"
              name="email"
              placeholder={
                props.lang === "it"
                  ? contentIt.email
                  : props.lang === "fr"
                  ? contentFr.email
                  : contentDe.email
              }
              className="fr_marginTopS"
              required
            />

            <input
              id="organisation"
              type="text"
              name="organisation"
              placeholder={
                props.lang === "it"
                  ? contentIt.organisation
                  : props.lang === "fr"
                  ? contentFr.organisation
                  : contentDe.organisation
              }
              className="fr_marginTopS"
            />

            <input
              id="adresszusatz"
              type="text"
              name="adresszusatz"
              placeholder={
                props.lang === "it"
                  ? contentIt.adresszusatz
                  : props.lang === "fr"
                  ? contentFr.adresszusatz
                  : contentDe.adresszusatz
              }
              className="fr_marginTopS"
            />

            <div className="flexbox">
              <input
                id="strasse"
                type="text"
                name="strasse"
                placeholder={
                  props.lang === "it"
                    ? contentIt.strasse
                    : props.lang === "fr"
                    ? contentFr.strasse
                    : contentDe.strasse
                }
                className="fr_marginTopS flex-4"
                required
              />
              <input
                id="nummer"
                type="number"
                name="nummer"
                placeholder={
                  props.lang === "it"
                    ? contentIt.nummer
                    : props.lang === "fr"
                    ? contentFr.nummer
                    : contentDe.nummer
                }
                className="fr_marginTopS flex-1"
              />
            </div>

            <div className="flexbox">
              <input
                id="plz"
                type="text"
                name="plz"
                placeholder={
                  props.lang === "it"
                    ? contentIt.plz
                    : props.lang === "fr"
                    ? contentFr.plz
                    : contentDe.plz
                }
                className="fr_marginTopS flex-1"
                required
              />
              <input
                id="ort"
                type="text"
                name="ort"
                placeholder={
                  props.lang === "it"
                    ? contentIt.ort
                    : props.lang === "fr"
                    ? contentFr.ort
                    : contentDe.ort
                }
                className="fr_marginTopS flex-4"
                required
              />
            </div>
            <input
              id="linkedin"
              type="text"
              name="linkedin"
              placeholder={
                props.lang === "it"
                  ? contentIt.linkedin
                  : props.lang === "fr"
                  ? contentFr.linkedin
                  : contentDe.linkedin
              }
              className="fr_marginTopS"
            />

            <label htmlFor="schonteilgenommen">
              <input
                id="schonteilgenommen"
                type="checkbox"
                name="schonteilgenommen"
                className="fr_marginTopM fr_marginBottomM"
                onChange={schonteilgenommenHandler}
              />
              {props.lang === "it"
                ? contentIt.schonteilgenommen
                : props.lang === "fr"
                ? contentFr.schonteilgenommen
                : contentDe.schonteilgenommen}
            </label>

            {!schonteilgenommen && (
              <>
                <div className="fr_posRel fr_marginBottomL">
                  <label for="file" className="fr-button-label">
                    {props.lang === "it"
                      ? contentIt.cv
                      : props.lang === "fr"
                      ? contentFr.cv
                      : contentDe.cv}
                  </label>
                  <input id="file" name="file" type="file" accept=".pdf" />
                </div>

                <textarea
                  id="motivation"
                  name="motivation"
                  placeholder={
                    props.lang === "it"
                      ? contentIt.motivation
                      : props.lang === "fr"
                      ? contentFr.motivation
                      : contentDe.motivation
                  }
                  className="fr_marginTopS"
                  rows="10"
                ></textarea>
                <textarea
                  id="bemerkungen"
                  name="bemerkungen"
                  placeholder={
                    props.lang === "it"
                      ? contentIt.bemerkungen
                      : props.lang === "fr"
                      ? contentFr.bemerkungen
                      : contentDe.bemerkungen
                  }
                  className="fr_marginTopS"
                  rows="10"
                ></textarea>
              </>
            )}

            <a
              href={
                props.lang === "it"
                  ? contentIt.link
                  : props.lang === "fr"
                  ? contentFr.link
                  : contentDe.link
              }
              target="_blank"
              rel="noreferrer"
              className="fr_displayBlock fr_marginTopXl"
            >
              {props.lang === "it"
                ? contentIt.anforderungsprofil
                : props.lang === "fr"
                ? contentFr.anforderungsprofil
                : contentDe.anforderungsprofil}
            </a>

            <div className="fr_clear"></div>

            <label htmlFor="verstanden">
              <input
                id="verstanden"
                type="checkbox"
                name="verstanden"
                className="fr_marginTopXl"
                required
              />
              {props.lang === "it"
                ? contentIt.verstanden
                : props.lang === "fr"
                ? contentFr.verstanden
                : contentDe.verstanden}
            </label>

            <div className="fr_clear fr_marginTopM">
              {props.lang === "it"
                ? contentIt.datenschutz
                : props.lang === "fr"
                ? contentFr.datenschutz
                : contentDe.datenschutz}
            </div>

            <Button
              type="button"
              onClick={backBtnHandler}
              className="fr_marginTopXl"
            >
              {props.lang === "fr"
                ? contentFr.system.zurueck
                : props.lang === "it"
                ? contentIt.system.zurueck
                : contentDe.system.zurueck}
            </Button>
            <Button type="submit">
              {props.lang === "fr"
                ? contentFr.system.absenden
                : props.lang === "it"
                ? contentIt.system.absenden
                : contentDe.system.absenden}
            </Button>
          </>
        </form>
      </Wrapper>
    </>
  );
};

export default Angaben;
