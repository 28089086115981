import React from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../shared/components/FormElements/Button";
import Wrapper from "../../shared/components/UIElements/Wrapper";
import Content from "../components/Content";

const Danke = () => {
  let history = useHistory();
  const lang = useParams().lang;
  const [contentDe, contentFr, contentIt] = Content;
  const zurueckHandler = () => {
    //history.push("/de/daten");
    history.push(`/${lang}/daten`);
  };

  return (
    <Wrapper>
      {lang === "fr" ? (
          <>
        <p>{contentFr.danke[0]}</p>
      <p>{contentFr.danke[1]}</p>
      <p>{contentFr.danke[2]}</p>
      </>
      ) : lang === "it" ? 
      (
        <>
      <p>{contentIt.danke[0]}</p>
    <p>{contentIt.danke[1]}</p>
    <p>{contentIt.danke[2]}</p>
    </>
    ) : 
    (
        <>
      <p>{contentDe.danke[0]}</p>
    <p>{contentDe.danke[1]}</p>
    <p>{contentDe.danke[2]}</p>
    </>
    )}
      

      <Button type="button" onClick={zurueckHandler}>
        {lang === "fr"
          ? "Retour au formulaire"
          : lang === "it"
          ? "Tornare al formulario"
          : "Zurück zum Formular"}
      </Button>
    </Wrapper>
  );
};

export default Danke;
