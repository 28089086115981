import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import "./Daten.css";

import Button from "../../shared/components/FormElements/Button";
import Card from "../../shared/components/UIElements/Card";
import Tage from "./Tage";

import IconBwt from "../../shared/media/pj-icon-bwt-bewerbungstraining.png";
import IconTel from "../../shared/media/pj-icon-bwt-telefon.png";

const Daten = (props) => {
  const [contentDe, contentFr, contentIt] = props.content;
  const [actionDates, setActionDates] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [chosenDate, setChosenDate] = useState([]);
  const [chosenKanton, setChosenKanton] = useState("");
  const [error, setError] = useState(null);
  let history = useHistory();
  const lang = useParams().lang;

  const navToAddressHandler = () => {
    props.onSelection(chosenDate);
    //props.detail={}
    history.push(`/${props.lang}/volunteer`);
    window.scrollTo({ top: 0 });
  };

  const kantonHandler = (event) => {
    setChosenKanton(event.target.value);
  };

  const errorHandler = () => {
    setError(null);
  };

  useEffect(() => {
    const sendRequest = async () => {
      try {
        const dates = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/${props.lang}/daten`
        );

        const responseData = await dates.json();

        if (!dates.ok) {
          throw new Error(responseData.message);
        }

        setActionDates(responseData);
        errorHandler();
      } catch (err) {
        setError(err.message);
      }
    };
    sendRequest();
  }, [props.lang]);

  console.log('chosenKanton: ', chosenKanton)

  const MappedActionDates = (props) =>
    actionDates.map((v, index) => {
      // Daten Bewerbungstraining
      const rawDate = new Date(v.date);
      const year = rawDate.getFullYear();
      const monthNum = rawDate.getMonth() + 1;
      const dayNum = rawDate.getDay();
      const dayDe = Tage.de[dayNum];
      const dayFr = Tage.fr[dayNum];
      const dayIt = Tage.it[dayNum];
      const day =
        props.lang === "de" ? dayDe : props.lang === "fr" ? dayFr : dayIt;
      const month = rawDate.getDate();
      const date = `${day}, ${month}. ${monthNum}. ${year}, ${v.time}`;

      // Daten Telefontraining
      let dateTel;
      const rawDateTel = new Date(v.datetel);
      if (v.datetel === undefined) {
        dateTel = "false";
      } else {
        if (v.datetel !== v.date) {
          const yearTel = rawDateTel.getFullYear();
          const monthNumTel = rawDateTel.getMonth() + 1;
          const dayNumTel = rawDateTel.getDay();
          const dayTelDe = Tage.de[dayNumTel];
          const dayTelFr = Tage.fr[dayNumTel];
          const dayTelIt = Tage.it[dayNumTel];
          const dayTel =
            props.lang === "de"
              ? dayTelDe
              : props.lang === "fr"
              ? dayTelFr
              : dayTelIt;
          const monthTel = rawDateTel.getDate();
          dateTel = `${dayTel}, ${monthTel}. ${monthNumTel}. ${yearTel}, ${v.timetel}`;
        } else {
          dateTel = "false";
        }
      }

      const hasBwt = rawDate.getTime() >= rawDateTel.getTime() ? true : false;

      const volunteersBenoetigt =
        props.lang === "fr"
          ? `${v.capacity} ${contentFr.benoetigt[1]}`
          : props.lang === "it"
          ? `${v.capacity} ${contentIt.benoetigt[1]}`
          : `${contentDe.benoetigt[0]} ${v.capacity} ${contentDe.benoetigt[1]}`;

      const selectionHandler = (itemIndex, id) => {
        if (selectedItems.includes(itemIndex)) {
          setSelectedItems((prevSelectedItems) =>
            prevSelectedItems.filter((item) => item !== itemIndex)
          );
        } else {
          setSelectedItems((prevSelectedItems) => [
            ...prevSelectedItems,
            itemIndex,
          ]);
        }

        if (!chosenDate.includes(id)) {
          setChosenDate((prevChosenDate) => [...prevChosenDate, id]);
        } else {
          const newArr = chosenDate.filter((d) => d !== id);
          setChosenDate(newArr);
        }
      };

      return (
        <>
        {chosenKanton === "" && (
        <Card
          key={v.id}
          className={`fr_pointer ${
            selectedItems.includes(index) ? "card--chosen" : ""
          }`}
          onClick={() => selectionHandler(index, v.id)}
        >
          {dateTel !== "false" && (
            <div className="dates--block">
              <p className="fr_fontBold">
                <img src={IconTel} alt="Icon Telefontraining" />
                {dateTel}
              </p>
            </div>
          )}
          {hasBwt && (
            <div className="dates--block">
              <p className="fr_fontBold">
                <img src={IconBwt} alt="Icon Bewerbungstraining" />
                {date}
              </p>
            </div>
          )}
          <p>{volunteersBenoetigt}</p>
          <p>{`${v.school}, ${v.plz} ${v.place}, ${v.canton}`}</p>
        </Card>
        )}
        {v.canton === chosenKanton && (
        <Card
          key={v.id}
          className={`fr_pointer ${
            selectedItems.includes(index) ? "card--chosen" : ""
          }`}
          onClick={() => selectionHandler(index, v.id)}
        >
          {dateTel !== "false" && (
            <div className="dates--block">
              <p className="fr_fontBold">
                <img src={IconTel} alt="Icon Telefontraining" />
                {dateTel}
              </p>
            </div>
          )}
          {hasBwt && (
            <div className="dates--block">
              <p className="fr_fontBold">
                <img src={IconBwt} alt="Icon Bewerbungstraining" />
                {date}
              </p>
            </div>
          )}
          <p>{volunteersBenoetigt}</p>
          <p>{`${v.school}, ${v.plz} ${v.place}, ${v.canton}`}</p>
        </Card>
        )}
        </>
      );
    });
  return (
    <>
      {error && (
        <p>
          {props.lang === "fr"
            ? contentFr.keinetermine
            : props.lang === "it"
            ? contentIt.keinetermine
            : contentDe.keinetermine}
        </p>
      )}
      <h2>{props.lang === "fr" ? contentFr.termine : props.lang === "it" ? contentIt.termine : contentDe.termine}</h2>
      <select
          name="newKanton"
          id="newKanton"
          className="newKanton"
          style={{ paddingLeft: "6px" }}
          defaultValue=""
          onChange={kantonHandler}
        >
          <option hidden value="">
            {props.lang === "fr" ? "Sélectionner le canton" : "Kanton auswählen"}
          </option>
          <option value="">{props.lang === "fr" ? "Toute la Suisse" : "Ganze Schweiz"}</option>
          <option value="AG">AG</option>
          <option value="AI">AI</option>
          <option value="AR">AR</option>
          <option value="BE">BE</option>
          <option value="BL">BL</option>
          <option value="BS">BS</option>
          <option value="FR">FR</option>
          <option value="GE">GE</option>
          <option value="GL">GL</option>
          <option value="GR">GR</option>
          <option value="JU">JU</option>
          <option value="LU">LU</option>
          <option value="NE">NE</option>
          <option value="NW">NW</option>
          <option value="OW">OW</option>
          <option value="SG">SG</option>
          <option value="SH">SH</option>
          <option value="SO">SO</option>
          <option value="SZ">SZ</option>
          <option value="TG">TG</option>
          <option value="TI">TI</option>
          <option value="UR">UR</option>
          <option value="VD">VD</option>
          <option value="VS">VS</option>
          <option value="ZG">ZG</option>
          <option value="ZH">ZH</option>
          
        </select>
      <MappedActionDates lang={props.lang} />
      {props.children}
      <Button
        type="button"
        onClick={navToAddressHandler}
        className="fr_marginTopXl"
      >
        {props.lang === "fr"
          ? contentFr.system.naechste
          : props.lang === "it"
          ? contentIt.system.naechste
          : contentDe.system.naechste}
      </Button>
    </>
  );
};

export default Daten;
