import React, { useState, useCallback } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import Auth from "./user/pages/Auth";
import BWTAdmin from "./bwt/pages/BwtAdmin";
import Anmelden from "./user/pages/Anmelden";
import Volunteer from "./bwt/pages/Volunteer";
import Danke from "./bwt/pages/Danke";
import { AuthContext } from "./shared/context/auth-context";
//import VolunteerTest from "./bwt/pages/Volunteer_test";
//import { useAuth } from "./shared/hooks/auth-hook";

const App = () => {
  //const { token, login, logout, userId } = useAuth();
  //const { login, logout, userId } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState(false);

  const login = useCallback((uid) => {
    setIsLoggedIn(true);
    setUserId(uid);
  }, []);

  const logout = useCallback(() => {
    setIsLoggedIn(false);
    setUserId(null);
  }, []);

  let routes;

  //console.log(`isLoggedIn: ${token}`);

  if (isLoggedIn) {
    routes = (
      <Switch>
        <Route path="/bwt-admin">
          <BWTAdmin />
        </Route>
        <Route path="/anmelden">
          <Anmelden />
        </Route>
        <Route path="/login">
          <Auth />
        </Route>
        <Route path="/:lang/danke">
          <Danke />
        </Route>
        <Route path="/:lang/:formpage">
          <Volunteer />
        </Route>
        <Route path="/:lang/:formpage">
          <Volunteer />
        </Route>
        <Redirect to="/login" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        {/* <Route path="/:lang/test">
          <VolunteerTest />
        </Route> */}
        <Route path="/login">
          <Auth />
        </Route>
        <Route path="/:lang/danke">
          <Danke />
        </Route>
        <Route path="/:lang/:formpage">
          <Volunteer />
        </Route>
        <Route path="/:lang/:formpage">
          <Volunteer />
        </Route>        
        <Redirect to="/de/daten" />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        userId: userId,
        login: login,
        logout: logout
      }}
    >
      <Router>
        <main>{routes}</main>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
