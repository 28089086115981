import React from 'react';

const Titel = (props) => {
    const [contentDe, contentFr, contentIt] = props.content;
    return (
        <>
        
        <h1>
          {props.lang === "fr"
            ? contentFr.anmeldung
            : props.lang === "it"
            ? contentIt.anmeldung
            : contentDe.anmeldung}
        </h1>
        <p>
            {props.lang=== "fr" ? contentFr.intro1 : props.lang === "it" ? contentIt.intro1 : contentDe.intro1}           
        </p>
        <p> {props.lang=== "fr" ? contentFr.intro2 : props.lang === "it" ? contentIt.intro2 : contentDe.intro2}</p>
        </>
        
    );
}

export default Titel;