import React from "react";

import ReactTooltip from "react-tooltip";

import "./Info.css";

import IconBwt from "../../shared/media/pj-icon-bwt-bewerbungstraining.png";
import IconTel from "../../shared/media/pj-icon-bwt-telefon.png";
import IconInfo from "../../shared/media/pj-icon-bwt-info.png";

const Info = (props) => {
  const [contentDe, contentFr, contentIt] = props.content;
  return (
    <div className={`info ${props.className}`}>
      <div className="info--column">
        <img src={IconBwt} alt="Icon Bewerbungstraining" />
        <p>
          {props.lang === "fr"
            ? contentFr.datumbewerbungstraining
            : props.lang === "it"
            ? contentIt.datumbewerbungstraining
            : contentDe.datumbewerbungstraining}
        </p>
        <img
          src={IconInfo}
          alt="Icon Info"
          onClick={props.onClickBwt}
          data-tip={props.lang === "it" ? contentIt.tooltip_bwt : props.lang === "fr" ? contentFr.tooltip_bwt : contentDe.tooltip_bwt}
        />
        <ReactTooltip
          effect="solid"
          data-offset="{'top': 10, 'left': 10}"
          className="tooltip--width"
        />
      </div>
      <div className="fr_clear"></div>
      <div className="info--column">
        <img src={IconTel} alt="Icon Telefontraining" />
        <p>
          {props.lang === "fr"
            ? contentFr.datumtelefonieren
            : props.lang === "it"
            ? contentIt.datumtelefonieren
            : contentDe.datumtelefonieren}
        </p>
        <img
          src={IconInfo}
          alt="Icon Info"
          onClick={props.onClickTel}          
          data-tip={props.lang === "it" ? contentIt.tooltip_tel : props.lang === "fr" ? contentFr.tooltip_tel : contentDe.tooltip_tel}
        />
      </div>
      <div className="fr_clear"></div>
    </div>
  );
};

export default Info;
