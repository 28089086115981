import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Wrapper from "../../shared/components/UIElements/Wrapper";
import Wunschregionen from "../components/Wunschregionen";
import Content from "../components/Content";
import Daten from "../components/Daten";
import Titel from "../components/Titel";
import Angaben from "../components/Angaben";
import Header from "../../shared/components/UIElements/Header";
import Info from "../components/Info";
//import Button from "../../shared/components/FormElements/Button";

//const [contentDe, contentFr, contentIt] = Content;

const Volunteer = () => {
  const lang = useParams().lang;
  const page = useParams().formpage;
  //let history = useHistory();
  const [dateids, setDateIds] = useState([]);
  const [wunschkanton, setWunschkanton] = useState([]);
  // const navToAddressHandler = () => {
  //   history.push(`/${lang}/test/volunteer`);
  // };
  const selectedDatesHandler = (chosenDates) => {
    //const selectedDates = Object.keys(chosenDates);
    setDateIds(chosenDates);
  };
  const kantoneHandler = (chosenKantone) => {
    setWunschkanton(chosenKantone);
  };

  return (
    <>
    <Header />
    <Wrapper>
      
      {page === "daten" && (
        <>        
          <Titel lang={lang} content={Content} />
          {lang !== "it" && (
          <Info lang={lang} content={Content} />
          )}
          <Daten
            lang={lang}
            content={Content}
            onSelection={selectedDatesHandler}
          >
            <Wunschregionen
              lang={lang}
              content={Content}
              onSelection={kantoneHandler}
            />
          </Daten>
        </>
      )}
      {page === "volunteer" && (
        <Angaben
          content={Content}
          lang={lang}
          dateids={dateids}
          wunschkanton={wunschkanton}
        />
      )}
    </Wrapper>
    </>
  );
};

export default Volunteer;
