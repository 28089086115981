import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import "./BwtAdmin.css";

import { AuthContext } from "../../shared/context/auth-context";

import Tage from "../components/Tage";
import Button from "../../shared/components/FormElements/Button";
import Card from "../../shared/components/UIElements/Card";
import Modal from "../../shared/components/UIElements/Modal";
import Wrapper from "../../shared/components/UIElements/Wrapper";
import Content from "../components/Content";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import Info from "../../bwt/components/Info";
import IconBwt from "../../shared/media/pj-icon-bwt-bewerbungstraining.png";
import IconTel from "../../shared/media/pj-icon-bwt-telefon.png";
//import Auth from "../../user/pages/Auth";

const [contentDe, contentFr, contentIt] = Content;

const BWTAdmin = (props) => {
  let history = useHistory();
  const auth = useContext(AuthContext);
  const navToNewUserHandler = () => {
    history.push("/anmelden");
  };
  const [lang, setLang] = useState("de");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [hasDates, setHasDates] = useState(false);

  const languageHandlerDe = () => {
    setLang("de");
  };
  const languageHandlerFr = () => {
    setLang("fr");
  };
  const languageHandlerIt = () => {
    setLang("it");
  };

  const [showNeuesDatum, setShowNeuesDatum] = useState(false);
  const showDatumHandler = () => setShowNeuesDatum(true);
  const closeDatumHandler = () => setShowNeuesDatum(false);

  const [chosenDate, setChosenDate] = useState("false");
  const [chosenDateTel, setChosenDateTel] = useState("false");
  const [sortDate, setSortDate] = useState("false");
  const [chosenTime, setChosenTime] = useState("false");
  const [chosenTimeTel, setChosenTimeTel] = useState("false");
  const [chosenSchool, setChosenSchool] = useState("");
  const [chosenPlz, setChosenPlz] = useState("");
  const [chosenOrt, setChosenOrt] = useState("");
  const [chosenKanton, setChosenKanton] = useState("");
  const [chosenCapacity, setChosenCapacity] = useState("");

  const [minusDate, setMinusDate] = useState("false");

  const [updateFetcher, setUpdateFetcher] = useState(0);

  const dateHandler = (event) => {
    setChosenDate(event.target.value);
    setSortDate(event.target.value);
  };
  const dateTelHandler = (event) => {
    setChosenDateTel(event.target.value);
    setSortDate(event.target.value);
    let date = new Date(event.target.value);
    date.setDate(date.getDate() - 1);
    date = date.toISOString().split("T")[0];
    setMinusDate(date);
    console.log(`datum minus 1 tag: ${date}`);
  };
  const timeHandler = (event) => {
    setChosenTime(event.target.value);
  };
  const timeTelHandler = (event) => {
    setChosenTimeTel(event.target.value);
  };
  const schoolHandler = (event) => {
    setChosenSchool(event.target.value);
  };
  const plzHandler = (event) => {
    setChosenPlz(event.target.value);
  };
  const ortHandler = (event) => {
    setChosenOrt(event.target.value);
  };
  const kantonHandler = (event) => {
    setChosenKanton(event.target.value);
  };
  const capacityHandler = (event) => {
    setChosenCapacity(event.target.value);
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    const neuesDatumData = {
      lang: lang,
      time: chosenTime,
      timetel: chosenTimeTel,
      date: chosenDate === "false" ? minusDate : chosenDate,
      datetel: chosenDateTel === "false" ? chosenDate : chosenDateTel,
      sort: sortDate,
      school: chosenSchool,
      plz: chosenPlz,
      place: chosenOrt,
      canton: chosenKanton,
      capacity: chosenCapacity,
    };

    //console.log(`details: ${neuesDatumData.canton}`);

    try {
      setIsLoading(true);
      console.log(`tel: ${neuesDatumData.timetel}`);
      console.log(
        `details: ${
          neuesDatumData.date === neuesDatumData.datetel
            ? "BWT"
            : neuesDatumData.date < neuesDatumData.datetel
            ? "Tel"
            : "BWT und Tel"
        }`
      );
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/bwt-admin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            //Authorization: 'Bearer ' + auth.token,
          },
          body: JSON.stringify({
            lang: neuesDatumData.lang,
            time: neuesDatumData.time,
            timetel: neuesDatumData.timetel,
            datetel: neuesDatumData.datetel,
            date: neuesDatumData.date,
            sort: neuesDatumData.sort,
            details:
              neuesDatumData.date === neuesDatumData.datetel
                ? "BWT"
                : neuesDatumData.date > neuesDatumData.datetel
                ? "Tel"
                : "BWT und Tel",
            school: neuesDatumData.school,
            plz: neuesDatumData.plz,
            place: neuesDatumData.place,
            canton: neuesDatumData.canton,
            capacity: neuesDatumData.capacity,
          }),
        }
      );

      const responseData = await response.json();
      // filter for 4** and 5** error codes
      if (!response.ok) {
        throw new Error(responseData.message);
      }
      setIsLoading(false);
      setUpdateFetcher(Math.random());
      closeDatumHandler();
      //history.push("/bwt-admin");
    } catch (err) {
      setIsLoading(false);
      closeDatumHandler();
      setError(
        err.message || "Ein Fehler ist aufgetreten, bitte versuche es nochmals"
      );
    }

    document.getElementById("newDate").value = "";
    document.getElementById("newDateTel").value = "false";
    document.getElementById("newTime").value = "";
    document.getElementById("newTimeTel").value = "";
    document.getElementById("newSchool").value = "";
    document.getElementById("newPlz").value = "";
    document.getElementById("newOrt").value = "";
    document.getElementById("newKanton").value = "";
    document.getElementById("newCapacity").value = "";
    setChosenDateTel("false");
    setChosenTimeTel("false");
  };

  const errorHandler = () => {
    setError(null);
  };

  const [chosenEditId, setChosenEditId] = useState();
  const [chosenEditTime, setChosenEditTime] = useState("");
  const [chosenEditTimeTel, setChosenEditTimeTel] = useState("");
  const [chosenEditDate, setChosenEditDate] = useState("");
  const [chosenEditDateTel, setChosenEditDateTel] = useState("");
  const [chosenEditSortDate, setChosenEditSortDate] = useState("");
  const [chosenEditSchool, setChosenEditSchool] = useState("");
  const [chosenEditPlz, setChosenEditPlz] = useState("");
  const [chosenEditOrt, setChosenEditOrt] = useState("");
  const [chosenEditKanton, setChosenEditKanton] = useState("");
  const [chosenEditCapacity, setChosenEditCapacity] = useState("");
  //const [editDatePlaceholder, setEditDatePlaceholder] = useState("");

  const dateEditHandler = (event) => {
    setChosenEditDate(event.target.value);
    setChosenEditSortDate(event.target.value);
  };
  const dateTelEditHandler = (event) => {
    setChosenEditDateTel(event.target.value);
    setChosenEditSortDate(event.target.value);
  };
  // const dateTelFocusHandler = () => {
  //   console.log("focused");
  //   document.getElementById('editDateTel').classList.remove();
  // }
  const timeEditHandler = (event) => {
    setChosenEditTime(event.target.value);
  };
  const timeEditTelHandler = (event) => {
    setChosenEditTimeTel(event.target.value);
  };
  const schoolEditHandler = (event) => {
    setChosenEditSchool(event.target.value);
  };
  const plzEditHandler = (event) => {
    setChosenEditPlz(event.target.value);
  };
  const ortEditHandler = (event) => {
    setChosenEditOrt(event.target.value);
  };
  const kantonEditHandler = (event) => {
    setChosenEditKanton(event.target.value);
  };
  const capacityEditHandler = (event) => {
    setChosenEditCapacity(event.target.value);
  };

  const [hasTraining, setHasTraining] = useState("both");

  const dateMinusHandler = (date) => {
    let editDate = new Date(date);
    editDate.setDate(editDate.getDate() - 1);
    editDate = editDate.toISOString().split("T")[0];
    console.log(`edit-datum minus 1 tag: ${editDate}`);
    return editDate;
  };

  const submitEditHandler = async (event) => {
    event.preventDefault();

    const editDatumData = {
      id: chosenEditId,
      time:
        chosenEditTime === undefined || chosenEditTime === ""
          ? "0 Uhr"
          : chosenEditTime,
      timetel:
        chosenEditTimeTel === undefined || chosenEditTimeTel === ""
          ? "0 Uhr"
          : chosenEditTimeTel,
      date:
        chosenEditDate === undefined || chosenEditDate === ""
          ? dateMinusHandler(chosenEditDateTel)
          : chosenEditDate,
      datetel:
        chosenEditDateTel === "" || chosenEditDateTel === undefined
          ? chosenEditDate
          : chosenEditDateTel,
      sort: chosenEditSortDate,
      school: chosenEditSchool,
      plz: chosenEditPlz,
      place: chosenEditOrt,
      canton: chosenEditKanton,
      capacity: chosenEditCapacity,
    };

    try {
      setIsLoading(true);
      console.log(`date: ${editDatumData.date}`);
      console.log(`teldate: ${editDatumData.datetel}`);
      console.log(`teltime: ${editDatumData.timetel}`);
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/bwt-admin",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            //Authorization: 'Bearer ' + auth.token,
          },
          body: JSON.stringify({
            id: editDatumData.id,
            time: editDatumData.time,
            timetel: editDatumData.timetel,
            date: editDatumData.date,
            datetel: editDatumData.datetel,
            sort: editDatumData.sort,
            details:
              editDatumData.date === editDatumData.datetel
                ? "BWT"
                : editDatumData.date > editDatumData.datetel
                ? "Tel"
                : "BWT und Tel",
            school: editDatumData.school,
            plz: editDatumData.plz,
            place: editDatumData.place,
            canton: editDatumData.canton,
            capacity: editDatumData.capacity,
          }),
        }
      );

      const responseData = await response.json();
      // filter for 4** and 5** error codes
      if (!response.ok) {
        throw new Error(responseData.message);
      }
      setIsLoading(false);
      setUpdateFetcher(Math.random());
      closeEditHandler();
      //history.push("/bwt-admin");
    } catch (err) {
      setIsLoading(false);
      closeEditHandler();
      setError(
        err.message || "Ein Fehler ist aufgetreten, bitte versuche es nochmals"
      );
    }
  };

  //const [chosenEditTraining, setChosenEditTraining] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const showEditHandler = () => {
    setShowEditModal(true);
  };
  const closeEditHandler = () => {
    setShowEditModal(false);
  };

  const editDateHandler = (v) => {
    // logik zur anzeige der default values in den inputs
    let bwtDate = new Date(v.date);
    let telDate = new Date(v.datetel);

    setHasTraining(
      bwtDate.getTime() === telDate.getTime()
        ? "bwt"
        : bwtDate.getTime() > telDate.getTime()
        ? "both"
        : "tel"
    );
    setChosenEditId(v.id);
    //setChosenEditTime(v.time);
    setChosenEditTime(
      bwtDate.getTime() === telDate.getTime()
        ? v.time
        : bwtDate.getTime() > telDate.getTime()
        ? v.time
        : ""
    );
    //setChosenEditTimeTel(v.timetel);
    setChosenEditTimeTel(
      bwtDate.getTime() === telDate.getTime()
        ? ""
        : bwtDate.getTime() > telDate.getTime()
        ? v.timetel
        : v.timetel
    );
    //setChosenEditDate(v.date);
    setChosenEditDate(
      bwtDate.getTime() === telDate.getTime()
        ? v.date
        : bwtDate.getTime() > telDate.getTime()
        ? v.date
        : ""
    );
    //setChosenEditDateTel(v.datetel);
    setChosenEditDateTel(
      bwtDate.getTime() === telDate.getTime()
        ? ""
        : bwtDate.getTime() > telDate.getTime()
        ? v.datetel
        : v.datetel
    );
    setChosenEditSchool(v.school);
    setChosenEditPlz(v.plz);
    setChosenEditOrt(v.place);
    setChosenEditKanton(v.canton);
    setChosenEditCapacity(v.capacity);
    showEditHandler();
  };

  const editDateFocusHandler = () => {
    console.log("focused");
    document.getElementById("editDate").classList.remove("fr_fontWhite");
  };
  const editTimeFocusHandler = () => {
    document.getElementById("editTime").classList.remove("fr_fontWhite");
  };
  const editDateTelFocusHandler = () => {
    document.getElementById("editDateTel").classList.remove("fr_fontWhite");
  };
  const editTimeTelFocusHandler = () => {
    document.getElementById("editTimeTel").classList.remove("fr_fontWhite");
  };

  const [zumLoeschen, setZumLoeschen] = useState(false);
  const zumLoeschenHandler = () => {
    setZumLoeschen(true);
  };
  const closeLoeschenHandler = () => {
    setZumLoeschen(false);
  };

  const loeschenHandler = () => {
    setShowEditModal(false);
    zumLoeschenHandler();
  };

  const loeschenSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/bwt-admin",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            //Authorization: 'Bearer ' + auth.token,
          },
          body: JSON.stringify({
            id: chosenEditId,
          }),
        }
      );

      const responseData = await response.json();
      // filter for 4** and 5** error codes
      if (!response.ok) {
        throw new Error(responseData.message);
      }
      setIsLoading(false);
      setUpdateFetcher(Math.random());
      closeLoeschenHandler();
    } catch (err) {
      setIsLoading(false);
      closeLoeschenHandler();
      setError(
        err.message || "Ein Fehler ist aufgetreten, bitte versuche es nochmals"
      );
    }
  };

  const [filteredVolunteerDates, setFilteredVolunteerDates] = useState([]);

  useEffect(() => {
    const sendRequest = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/bwt-admin/${lang}`
        );

        const responseData = await response.json();

        if (!response.ok) {
          throw new Error(responseData.message);
        }

        setFilteredVolunteerDates(responseData);
        setHasDates(true);
      } catch (err) {
        //throw new Error(err.message);
        setHasDates(false);
        setError(
          err.message || "Es konnten keine aktuellen Trainings gefunden werden."
        );
      }
    };
    sendRequest();
  }, [lang, updateFetcher]);

  console.log("chosenKanton: ", chosenKanton);

  return (
    <div className="bwt--admin">
      <Modal
        show={showEditModal}
        onCancel={closeEditHandler}
        header="Training bearbeiten"
        contentClass="place-item__modal-content"
        footerClass="place-item__modal-actions"
        footer={
          <>
            <Button type="submit">SPEICHERN</Button>
            <Button
              type="button"
              className="button--danger"
              onClick={loeschenHandler}
            >
              LÖSCHEN
            </Button>
            <Button
              type="button"
              onClick={closeEditHandler}
              className="button--inverse"
            >
              ABBRECHEN
            </Button>
          </>
        }
        onSubmit={submitEditHandler}
      >
        <label
          htmlFor="editDateTel"
          className={`${lang === "it" && "fr_hide"}`}
        >
          Datum / Zeit für Telefontraining
        </label>
        <input
          id="editDateTel"
          type="date"
          name="editDateTel"
          className={`fr_marginTopS ${lang === "it" && "fr_hide"} ${
            hasTraining === "bwt" && "fr_fontWhite"
          }`}
          onChange={dateTelEditHandler}
          onFocus={editDateTelFocusHandler}
          defaultValue={chosenEditDateTel}
          placeholder="Datum"
        />
        <input
          id="editTimeTel"
          type="text"
          name="editTimeTel"
          className={`fr_marginTopS ${lang === "it" && "fr_hide"} ${
            hasTraining === "bwt" && "fr_fontWhite"
          }`}
          onChange={timeEditTelHandler}
          onFocus={editTimeTelFocusHandler}
          defaultValue={chosenEditTimeTel === "false" ? "" : chosenEditTimeTel}
          placeholder="Zeit Telefontraining"
        />
        <label htmlFor="editDate">Datum / Zeit für Bewerbungstraining</label>
        <input
          id="editDate"
          type="date"
          name="editDate"
          className={`fr_marginTopS ${hasTraining === "tel" && "fr_fontWhite"}`}
          onChange={dateEditHandler}
          onFocus={editDateFocusHandler}
          defaultValue={chosenEditDate}
          placeholder="Datum"
        />
        <input
          id="editTime"
          type="text"
          name="editTime"
          className={`fr_marginTopS ${hasTraining === "tel" && "fr_fontWhite"}`}
          onChange={timeEditHandler}
          onFocus={editTimeFocusHandler}
          defaultValue={chosenEditTime}
          placeholder="Zeit Bewerbungstraining"
        />
        <input
          id="editSchool"
          type="text"
          name="editSchool"
          placeholder="Schule"
          className="fr_marginTopS"
          onChange={schoolEditHandler}
          defaultValue={chosenEditSchool}
        />
        <input
          id="editPlz"
          type="text"
          name="editPlz"
          placeholder="PLZ"
          className="fr_marginTopS"
          onChange={plzEditHandler}
          defaultValue={chosenEditPlz}
        />
        <input
          id="editOrt"
          type="text"
          name="editOrt"
          placeholder="Ort"
          className="fr_marginTopS"
          onChange={ortEditHandler}
          defaultValue={chosenEditOrt}
        />
        {/* <input
          id="editKanton"
          type="text"
          name="editKanton"
          placeholder="Kanton"
          className="fr_marginTopS"
          onChange={kantonEditHandler}
          defaultValue={chosenEditKanton}
        /> */}
        <select
          name="editKanton"
          id="editKanton"
          className="fr_marginTopS"
          style={{ paddingLeft: "6px" }}
          defaultValue={chosenEditKanton}
          onChange={kantonEditHandler}
        >
          <option hidden value="">
            Kanton auswählen
          </option>
          <option value="AG">AG</option>
          <option value="AI">AI</option>
          <option value="AR">AR</option>
          <option value="BE">BE</option>
          <option value="BL">BL</option>
          <option value="BS">BS</option>
          <option value="FR">FR</option>
          <option value="GE">GE</option>
          <option value="GL">GL</option>
          <option value="GR">GR</option>
          <option value="JU">JU</option>
          <option value="LU">LU</option>
          <option value="NE">NE</option>
          <option value="NW">NW</option>
          <option value="OW">OW</option>
          <option value="SG">SG</option>
          <option value="SH">SH</option>
          <option value="SO">SO</option>
          <option value="SZ">SZ</option>
          <option value="TG">TG</option>
          <option value="TI">TI</option>
          <option value="UR">UR</option>
          <option value="VD">VD</option>
          <option value="VS">VS</option>
          <option value="ZG">ZG</option>
          <option value="ZH">ZH</option>
        </select>
        <input
          id="newCapacity"
          type="number"
          name="newCapacity"
          placeholder="Kapazität"
          className="fr_marginTopS"
          onChange={capacityEditHandler}
          defaultValue={chosenEditCapacity}
        />
      </Modal>

      <Modal
        show={zumLoeschen}
        onCancel={closeLoeschenHandler}
        header="Wirklich löschen?"
        contentClass="place-item__modal-content"
        footerClass="place-item__modal-actions"
        footer={
          <>
            <Button type="submit">LÖSCHEN</Button>
            <Button
              type="button"
              onClick={closeLoeschenHandler}
              className="button--inverse"
            >
              ABBRECHEN
            </Button>
          </>
        }
        onSubmit={loeschenSubmitHandler}
      >
        <input
          id="loeschenId"
          type="hidden"
          name="loeschenId"
          value={chosenEditId}
        />
      </Modal>

      <Modal
        show={showNeuesDatum}
        onCancel={closeDatumHandler}
        header={`Neues Bewerbungstraining ${
          lang === "de" ? "DE" : lang === "fr" ? "FR" : "IT"
        }`}
        contentClass="place-item__modal-content"
        footerClass="place-item__modal-actions"
        footer={
          <>
            <Button type="submit">SPEICHERN</Button>
            <Button
              type="button"
              onClick={closeDatumHandler}
              className="button--inverse"
            >
              ABBRECHEN
            </Button>
          </>
        }
        onSubmit={submitHandler}
      >
        <input id="newLang" type="hidden" name="newLang" value={lang} />
        <label htmlFor="newDateTel" className={lang === "it" && "fr_hide"}>
          Datum / Zeit für Telefontraining
        </label>
        <input
          id="newDateTel"
          type="date"
          name="newDateTel"
          className={`fr_marginTopS ${lang === "it" && "fr_hide"}`}
          onChange={dateTelHandler}
        />
        <input
          id="newTimeTel"
          type="text"
          name="newTimeTel"
          placeholder="Zeit Telefontraining"
          className={`fr_marginTopS ${lang === "it" && "fr_hide"}`}
          onChange={timeTelHandler}
        />
        <label htmlFor="newDate">Datum / Zeit für Bewerbungstraining</label>
        <input
          id="newDate"
          type="date"
          name="newDate"
          className="fr_marginTopS"
          onChange={dateHandler}
        />
        <input
          id="newTime"
          type="text"
          name="newTime"
          placeholder="Zeit Bewerbungstraining"
          className="fr_marginTopS"
          onChange={timeHandler}
        />
        <input
          id="newSchool"
          type="text"
          name="newSchool"
          placeholder="Schule"
          className="fr_marginTopS"
          onChange={schoolHandler}
        />
        <input
          id="newPlz"
          type="text"
          name="newPlz"
          placeholder="PLZ"
          className="fr_marginTopS"
          onChange={plzHandler}
        />
        <input
          id="newOrt"
          type="text"
          name="newOrt"
          placeholder="Ort"
          className="fr_marginTopS"
          onChange={ortHandler}
        />
        {/* <input
          id="newKanton"
          type="text"
          name="newKanton"
          placeholder="Kanton"
          className="fr_marginTopS"
          onChange={kantonHandler}
        /> */}
        <select
          name="newKanton"
          id="newKanton"
          className="fr_marginTopS"
          style={{ paddingLeft: "6px" }}
          defaultValue=""
          onChange={kantonHandler}
        >
          <option hidden value="">
            Kanton auswählen
          </option>
          <option value="AG">AG</option>
          <option value="AI">AI</option>
          <option value="AR">AR</option>
          <option value="BE">BE</option>
          <option value="BL">BL</option>
          <option value="BS">BS</option>
          <option value="FR">FR</option>
          <option value="GE">GE</option>
          <option value="GL">GL</option>
          <option value="GR">GR</option>
          <option value="JU">JU</option>
          <option value="LU">LU</option>
          <option value="NE">NE</option>
          <option value="NW">NW</option>
          <option value="OW">OW</option>
          <option value="SG">SG</option>
          <option value="SH">SH</option>
          <option value="SO">SO</option>
          <option value="SZ">SZ</option>
          <option value="TG">TG</option>
          <option value="TI">TI</option>
          <option value="UR">UR</option>
          <option value="VD">VD</option>
          <option value="VS">VS</option>
          <option value="ZG">ZG</option>
          <option value="ZH">ZH</option>
        </select>
        <input
          id="newCapacity"
          type="number"
          name="newCapacity"
          placeholder="Kapazität"
          className="fr_marginTopS"
          onChange={capacityHandler}
        />
      </Modal>
      <Wrapper>
        <ErrorModal error={error} onClear={errorHandler} />
        <div>
          <h1 className="fr_displayInline">
            BWT Admin {lang === "it" ? " IT" : lang === "fr" ? " FR" : " DE"}
          </h1>
          <div className="fr_displayInline fr_floatRight">
            <Button
              type="button"
              className={`${lang !== "de" && "button--inverse"} small`}
              onClick={languageHandlerDe}
            >
              DE
            </Button>
            <Button
              type="button"
              className={`${lang !== "fr" && "button--inverse"} small`}
              onClick={languageHandlerFr}
            >
              FR
            </Button>
            <Button
              type="button"
              className={`${lang !== "it" && "button--inverse"} small`}
              onClick={languageHandlerIt}
            >
              IT
            </Button>
          </div>
        </div>

        <div className="erstellen--btns">
          <Button type="button" onClick={showDatumHandler}>
            Neues Datum erstellen
          </Button>

          <Button
            type="button"
            onClick={navToNewUserHandler}
            className="button--inverse"
          >
            Neuen Benutzer erstellen
          </Button>
        </div>
        {lang !== "it" && (
          <Info lang={lang} content={Content} className="admin--info" />
        )}

        <div className="fr_clear"></div>
        {isLoading && <LoadingSpinner asOverlay />}
        {filteredVolunteerDates.map((v, index) => {
          const rawDate = new Date(v.date);
          const year = rawDate.getFullYear();
          const monthNum = rawDate.getMonth() + 1;
          const dayNum = rawDate.getDay();
          const dayDe = Tage.de[dayNum];
          const dayFr = Tage.fr[dayNum];
          const dayIt = Tage.it[dayNum];
          const day = lang === "de" ? dayDe : lang === "fr" ? dayFr : dayIt;
          const month = rawDate.getDate();
          const date = `${day}, ${month}. ${monthNum}. ${year}, ${v.time}`;

          // Daten Telefontraining
          let dateTel;

          const rawDateTel = new Date(v.datetel);
          if (v.datetel === undefined) {
            dateTel = "false";
          } else {
            if (v.datetel !== v.date) {
              const yearTel = rawDateTel.getFullYear();
              const monthNumTel = rawDateTel.getMonth() + 1;
              const dayNumTel = rawDateTel.getDay();
              const dayTelDe = Tage.de[dayNumTel];
              const dayTelFr = Tage.fr[dayNumTel];
              const dayTelIt = Tage.it[dayNumTel];
              const dayTel =
                lang === "de" ? dayTelDe : lang === "fr" ? dayTelFr : dayTelIt;
              const monthTel = rawDateTel.getDate();
              dateTel = `${dayTel}, ${monthTel}. ${monthNumTel}. ${yearTel}, ${v.timetel}`;
            } else {
              dateTel = "false";
            }
          }

          //console.log (`${rawDate.getTime()} und ${rawDateTel.getTime()}`);
          const hasBwt =
            rawDate.getTime() >= rawDateTel.getTime() ? true : false;

          const today = new Date();
          today.setHours(0, 0, 0, 0);

          const volunteersBenoetigt =
            lang === "fr"
              ? `${v.capacity} ${contentFr.benoetigt[1]}`
              : lang === "it"
              ? `${v.capacity} ${contentIt.benoetigt[1]}`
              : `${contentDe.benoetigt[0]} ${v.capacity} ${contentDe.benoetigt[1]}`;

          //   if(hasDates){
          const datesBlock = hasDates ? (
            <Card
              key={v.id}
              className="hover"
              onClick={() => editDateHandler(v)}
            >
              {v.capacity === 0 && (
                <p className="fr_fontYellow fr_fontBold">
                  Dieses Training ist im Frontend nicht (mehr) ersichtlich
                </p>
              )}
              {dateTel !== "false" && lang !== "it" && (
                <p>
                  <img className="admin--icon" src={IconTel} alt="Icon" />
                  <span className="fr_fontBold">{dateTel}</span>
                </p>
              )}
              {hasBwt && (
                <p>
                  <img className="admin--icon" src={IconBwt} alt="Icon" />
                  <span className="fr_fontBold">{date} </span>{" "}
                </p>
              )}
              <p>{volunteersBenoetigt}</p>
              <p className="fr_displayInline">{`${v.school}, ${v.plz} ${v.place}, ${v.canton}`}</p>
            </Card>
          ) : (
            ""
          );

          return datesBlock;
          //   }
        })}
      </Wrapper>
    </div>
  );
};

export default BWTAdmin;
