import React from 'react';
import { useParams } from "react-router-dom";

import Modal from './Modal';
import Button from '../FormElements/Button';


const ErrorModal = props => {
  const lang = useParams().lang;

  return (
    <Modal
      onCancel={props.onClear}    
      header={lang==="fr"?"Erreur":lang==="it"?"Errore":"Fehler"}
      show={!!props.error}
      footer={<Button onClick={props.onClear}>OK</Button>}
    >
      <p>{props.error}</p>
    </Modal>
  );
};

export default ErrorModal;
