import React from "react";

import "./Card.css";

const Card = (props) => {
  // const [chosenDate, setChosenDate] = useState(false);
  // const chooseDateHandler = () => {
  //   chosenDate ? setChosenDate(false) : setChosenDate(true);
  // };
  // let usersDates = [];

  return (
    <div
      className={`card ${props.className}`}
      style={props.style}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

export default Card;
