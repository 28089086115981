const Content = [
  {
    system: {
      naechste: "Anmelden",
      zurueck: "< ZURÜCK",
      absenden: "ABSENDEN",
      fehler1:
        "Ein Fehler ist aufgetreten, bitte versuche es später noch einmal.",
    },
    intro1: `Für die nachfolgenden Termine suchen wir Volunteers, die mit Schülerinnen und Schülern der Sekundarstufe das Bewerbungstraining durchführen. Pro Juventute bietet neu das Bewerbungstraining mit dem optionalen Modul «Telefonieren üben mit Lehrbetrieb» den Schulen an.`,
    intro2: `Achten Sie bei Ihrer Terminwahl darauf, ob es sich um ein «Bewerbungstraining» oder ein «Telefonieren üben» handelt («Bewerbungstraining» findet in der Schule vor Ort statt und «Telefonieren üben» findet telefonisch statt).`,
    anmeldung: "Ihre Anmeldung",
    datumtelefonieren: "Datum 'Telefonieren üben'",
    datumbewerbungstraining: "Datum 'Bewerbungstraining'",
    tooltip_tel:
      "Dies ist ein Termin mit der Zusatzoption «Telefonieren üben». An diesem Termin sind Sie für 2 Stunden telefonisch erreichbar. Die Schüler und Schülerinnen rufen Sie an und es wird ein Telefongespräch mit einem Lehrbetrieb simuliert.",
    tooltip_bwt:
      "Dieses Datum weist auf einen Einsatz für ein Bewerbungstraining vor Ort oder Online hin. Der Einsatz kann auf dieses Datum beschränkt sein oder in Kombination mit einem Telefontermin sein.",
    verfuegung:
      "Gerne stelle ich mich für folgendes Bewerbungstraining als Volunteer zur Verfügung",
    information:
      "Ich möchte per E-Mail informiert werden, wenn in folgender/n Wunschregion/en Einsatztermine aufgeschaltet sind (bitte wählen Sie Ihre Wunschregion):",
    wahl: "Bitte wählen Sie Ihre Wunschregion",
    kantone: [
      "AG",
      "AI/AR",
      "BL/BS",
      "BE",
      "FR",
      "GL",
      "GR",
      "LU",
      "NW/OW",
      "SG",
      "SH",
      "SZ",
      "SO",
      "TG",
      "VS",
      "ZG",
      "ZH",
    ],
    titleOrigin: "Ich melde mich an*",
    privatperson: "als Privatperson",
    arbeitgeber: "über meinen Arbeitgeber",
    anrede: "Anrede*",
    herr: "Herr",
    frau: "Frau",
    divers: "Divers",
    vorname: "Vorname*",
    name: "Name*",
    mobile: "Mobile*",
    email: "E-Mail*",
    organisation: "Organisation (Anm. über Arbeitgeber)",
    adresszusatz: "Adresszusatz",
    strasse: "Strasse*",
    nummer: "Nr.",
    plz: "PLZ*",
    ort: "Ort*",
    schonteilgenommen:
      "Ich habe schon mal an einem Bewerbungstraining teilgenommen",
    linkedin: "Link zum LinkedIn Profil",
    motivation: "Ihre Motivation*",
    bemerkungen: "Weitere Bemerkungen",
    anforderungsprofil: "ANFORDERUNGSPROFIL",
    link: "https://www.projuventute.ch/sites/default/files/2021-04/anforderungsprofil-volunteers-bewerbungstraining.pdf",
    verstanden:
      "Ich habe das Anforderungsprofil genau gelesen und bestätige, dass meine Erfahrungen dazu passen*",
    benoetigt: ["noch", "Trainer*in/nen benötigt"],
    danke: [
      "Vielen Dank für Ihr Interesse an unserem Angebot.",
      "Sie erhalten in Kürze ein Mail von uns.",
      "Freundliche Grüsse, Ihre Pro Juventute",
    ],
    termine: "Termine",
    keinetermine: "Es sind in dieser Sprache aktuell keine Termine verfügbar.",
    cv: "CV hochladen",
    datenschutz:
      "Mit der Anmeldung ermächtigen Sie die Stiftung  Pro Juventute, Ihre Daten unter Einhaltung der Datenschutzverordnung im Rahmen des Ihres freiwilligen Engagements zu bearbeiten.",
  },
  {
    system: {
      naechste: "Inscriptions",
      zurueck: "< PRÉCÉDENT",
      absenden: "SOUMETTRE",
      fehler1: "Une erreur s'est produite, veuillez réessayer plus tard.",
    },
    intro1: `Nous cherchons des bénévoles pour participer aux journées Entretiens fictifs et au module complémentaire Téléphoner à une entreprise pour les dates listées ci-dessous.`,
    intro2: `Quelques écoles seulement participent au module complémentaire Téléphoner à une entreprise. Si l’école qui vous intéresse participe aux deux modules, nous vous encouragerons vivement à vous inscrire à la journée Entretiens fictifs + au module complémentaire Téléphoner à une entreprise. Pour vous et pour les élèves, cela permet un meilleur suivi. Si toutefois, vous n'êtes pas disponible pour les deux dates, vous pouvez bien sûr vous inscrire uniquement pour les Entretiens fictifs ou uniquement pour Téléphoner à une entreprise.`,
    anmeldung: "Votre inscription",
    datumtelefonieren: "Module complémentaire Téléphoner à une entreprise",
    datumbewerbungstraining: "Journée Entretiens fictifs",
    tooltip_tel:
      "Cette date indique un engagement d’une demi-journée pour le module Téléphoner à une entreprise. Vous réalisez cet engagement par téléphone depuis votre domicile ou votre lieu de travail. L’intervention peut être limitée à ce module ou combinée à une journée Entretiens fictifs.",
    tooltip_bwt:
      "Cette date indique un engagement d’une journée pour les entretiens fictifs dans les locaux d’une école. L'intervention peut être limitée à cette journée ou être combinée avec la date du module «téléphoner à une entreprise»",
    verfuegung:
      "Je suis disponible pour participer à la journée «entretiens fictifs» suivante en tant que bénévole:",
    information:
      "Je souhaite être informé*e par email lorsque de nouvelles dates Entretiens fictifs sont disponibles dans la/les région*s de mon choix. Veuillez svp sélectionner la région pour laquelle vous êtes disponible. (plusieurs régions possibles)",
    wahl: "Veuillez sélectionner la région de votre choix: (plusieurs choix possibles)",
    kantone: ["BE", "FR", "GE", "JU", "NE", "VD", "VS"],
    titleOrigin: "Je m'inscris*",
    privatperson: "en tant que personne privée",
    arbeitgeber: "par mon employeur",
    anrede: "Formule d'appel*",
    herr: "Monsieur",
    frau: "Madame",
    divers: "Divers",
    vorname: "Prénom*",
    name: "Nom*",
    mobile: "Mobile*",
    email: "E-Mail*",
    organisation: "Entreprise (inscr. par l'employeur)",
    adresszusatz: "Complément d'adresse",
    strasse: "Rue*",
    nummer: "N°",
    plz: "CP*",
    ort: "Lieu*",
    schonteilgenommen: "Je suis déjà bénévole pour Pro Juventute",
    linkedin: "LinkedIn",
    motivation: "Votre motivation*",
    bemerkungen: "Autres remarques",
    anforderungsprofil: "PROFIL",
    link: "https://www.projuventute.ch/sites/default/files/2022-06/bwt-profil-demande.pdf",
    verstanden:
      "J'ai lu attentivement le profil demandé et confirme que mon expérience correspond*",
    benoetigt: ["", "place(s) encore disponible(s)"],
    danke: [
      "Merci beaucoup de l’intérêt que vous portez à notre offre.",
      "Vous recevrez prochainement un e-mail de notre part.",
      "Pro Juventute",
    ],
    termine: "Dates d’engagement",
    keinetermine:
      "Il n'y a actuellement aucune journée de disponible dans cette langue.",
    cv: "Télécharger CV",
    datenschutz:
      "En vous inscrivant, vous autorisez la Fondation Pro Juventute à traiter vos données dans le cadre strict de votre engagement bénévole.",
  },
  {
    system: {
      naechste: "Inscrizione",
      zurueck: "< INDIETRO",
      absenden: "INVIA",
      fehler1: "Si è verificato un errore, si prega di ripovare più tardi",
    },
    intro1: `Siamo alla ricerca di volontari per svolgere le prove di colloquio con allievi delle scuole medie.`,
    intro2: ``,
    anmeldung: "Inscrizione",
    datumtelefonieren: "Datum 'Telefonieren üben'",
    datumbewerbungstraining: "Datum 'Bewerbungstraining'",
    tooltip_tel:
      "Dies ist ein Termin mit der Zusatzoption «Telefonieren üben». An diesem Termin sind Sie für 2 Stunden telefonisch erreichbar. Die Schüler und Schülerinnen rufen Sie an und es wird ein Telefongespräch mit einem Lehrbetrieb simuliert.",
    tooltip_bwt:
      "Dieses Datum weist auf einen Einsatz für ein Bewerbungstraining vor Ort oder Online hin. Der Einsatz kann auf dieses Datum beschränkt sein oder in Kombination mit einem Telefontermin sein.",
    verfuegung:
      "Gerne stelle ich mich für folgendes Bewerbungstraining als Volunteer zur Verfügung",
    information: "",
    wahl: "Bitte wählen Sie Ihre Wunschregion",
    kantone: [
      "Aargau",
      "Appenzell Ausserrhoden",
      "Appenzell Innerrhoden",
      "Basel-Land",
      "Basel-Stadt",
      "Bern",
      "Freiburg",
      "Genf",
      "Glarus",
      "Graubünden",
      "Jura",
      "Luzern",
      "Neuchâtel",
      "Nidwalden",
      "Obwalden",
      "St. Gallen",
      "Schaffhausen",
      "Schwyz",
      "Solothurn",
      "Thurgau",
      "Tessin",
      "Uri",
      "Vaud",
      "Wallis",
      "Zug",
      "Zürich",
    ],
    titleOrigin: "Mi iscrivo*",
    privatperson: "come persona privata",
    arbeitgeber: "tramite il mio datore di lavoro",
    anrede: "Appellativo",
    herr: "Signore",
    frau: "Signora",
    divers: "Diverso",
    vorname: "Nome",
    name: "Cognome",
    mobile: "Mobile",
    email: "E-Mail",
    organisation: "Azienda (si partecipo tramite il mio datore di lavoro)",
    adresszusatz: "Supplemento di indirizzo",
    strasse: "Via",
    nummer: "N°",
    plz: "NPA*",
    ort: "Località*",
    schonteilgenommen:
      "IT: Ich habe schon mal an einem Bewerbungstraining teilgenommen",
    linkedin: "LinkedIn",
    motivation: "IT: Ihre Motivation*",
    bemerkungen: "Osservazioni/informazioni sulle sue esperienze*",
    anforderungsprofil: "PROFILO DEI REQUISITI",
    link: "https://www.projuventute.ch/sites/default/files/2020-12/Profilo-dei-requisiti.pdf",
    verstanden:
      "Ho letto il profilo dei requisiti e confermo di avere l'esperienza necessaria per svolegere il compito*",
    benoetigt: ["", "volontari ancora necessari"],
    danke: [
      "Grazie mille per il vostro interesse alla nostra offerta.",
      "Riceverete una nostra email a breve.",
      "La vostra Pro Juventute",
    ],
    termine: "Date",
    keinetermine: "Al momento non ci sono date per la Svizzera italiana.",
    cv: "CV",
    datenschutz:
      "Con la registrazione, autorizzate la Fondazione Pro Juventute a trattare i vostri dati nell'ambito della vostra attività di volontariato.",
  },
];

export default Content;
