import React, { useEffect, useState } from "react";

import Card from "../../shared/components/UIElements/Card";

const Wunschregionen = (props) => {
  const [contentDe, contentFr, contentIt] = props.content;
  const kantone =
    props.lang === "de"
      ? contentDe.kantone
      : props.lang === "fr"
      ? contentFr.kantone
      : [];

  const [wunschkanton, setWunschKanton] = useState([]);
  //const dieKantone = [];

  const wunschkantonHandler = (kanton, index) => {
    if (!wunschkanton.includes(kanton)) {
      setWunschKanton((prevWunschkanton) => [...prevWunschkanton, kanton]);
    } else {
      const newArr = wunschkanton.filter((k) => k !== kanton);
      setWunschKanton(newArr);
    }
    
    //props.onSelection(wunschkanton);
  };

  useEffect(() => {
    props.onSelection(wunschkanton);
}, [props, wunschkanton])

//   const chosenKantonHandler = () => {
//       props.onSelection(wunschkanton);
//   }

  return (
    <>
      <p className="fr_fontBold">
        {props.lang === "fr"
          ? contentFr.information
          : props.lang === "it"
          ? contentIt.information
          : contentDe.information}
      </p>

      <div className="fr_clear fr_marginTopM"></div>
      {props.lang !== "it" && (
        <Card>
          {kantone.map((k, i) => (
            <div
              key={i}
              //onClick={() => {wunschkantonHandler(k, i); chosenKantonHandler()}}
              onClick={() => wunschkantonHandler(k, i)}
              className={`fr_displayInlineBlock fr_pointer fr_paddingS ${
                wunschkanton.includes(k) ? "fr_underlineYellow" : ""
              }`}
            >
              {k}
            </div>
          ))}
        </Card>
      )}

      <div className="fr_clear"></div>
    </>
  );
};

export default Wunschregionen;
